import React, { Component } from 'react';
import * as styles from "./style.module.css"

import ControlRadio from "../controls/radio"
import ControlSelect from "../controls/select"
import PaintTable from "./painttable"

import substratelist from "../../content/substrate.json"
import substrateradiolist from "../../content/substrateradiolist.json"

class PaintCalculator extends Component {
	state = {
		substrate: 'concrete',
		detail: 'CIPN',
		area: 0
	}

	onAreaChange(event) {
		if (event.target.value >= 0)
			this.setState({area: event.target.value})
		else
			this.setState({area: 0})
		console.log(this.state);
	}

	handleDetailChange = (newvalue) => {
		this.setState({detail: newvalue});
	}

	handleSubstrateChange = (newvalue) => {
		this.setState({
			substrate: newvalue,
			detail: substratelist[newvalue][0].code
		});
	}


	render() {

		const {
			handleSubstrateChange,
			handleDetailChange
		} = this
		return(
			<>
				<div className={styles.surfaceform+" color-border-primary"}>
					<div className={styles.surfaceformsubstrate}>
						<div className={styles.surfaceformfield}>
							<label htmlFor="substrate" className={"color-text-primary "+styles.controllabel}>1. Substrate</label>
							<ControlRadio
									id="substrate"
									default="concrete"
									handleChange={handleSubstrateChange}
									list={substrateradiolist}
								/>
						</div>
						<div className={styles.surfaceformfield}>
							<label htmlFor="detail" className={"color-text-primary "+styles.controllabel}>2. Substrate Details</label>
							<ControlSelect
									id="detail"
									handleChange={handleDetailChange}
									list={substratelist[this.state.substrate]}
								/>
						</div>
						<div className={styles.surfaceformfield}>
							<label htmlFor="areavalue" className={"color-text-primary "+styles.controllabel}>3. Area in SQM</label>
							<input id="areavalue" className={"color-background-primary "+styles.areavalue}
								type="number" value={this.state.area} onChange={this.onAreaChange.bind(this)} />
						</div>
					</div>
				</div>
				<PaintTable
						surface={this.state.detail}
						areasqm={this.state.area}
					/>
			</>
		);
	}
}


export default PaintCalculator;

