import React from "react"

import offset from './desktop-offset.png'
import offsetmobile from './mobile-offset.png'


const HeaderOffset = () => {
  return (
  	<>
	<img alt="" src={offset} class="fullblock-desktop-only"/>
	<img alt="" src={offsetmobile} class="fullblock-mobile-only"/>
	</>
  )

}

export default HeaderOffset
