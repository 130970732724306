import React from "react"
import PropTypes from "prop-types"

import * as styles from "./table.module.css"

import surfacelist from "../../content/surfacelist.json"

const PaintTable = ({ surface, areasqm }) => {
  return (
	<table cellspacing={0} cellpadding={0} className={styles.painttable+ " font-size-regular font-style-bold"}>
		<thead>
			<tr>
				<th className={styles.painttableheader+" color-text-primary"}>Use</th>
				<th className={styles.painttableheader+" color-text-primary"}>Product</th>
				<th className={styles.painttableheader+" color-text-primary"}>Liters</th>
			</tr>
		</thead>
		<tbody>
			{surfacelist[surface].map((data, index) => {
				return (
					data.header?(
						data.notes === "" ?
							<tr className={index===0?styles.painttablerow: styles.painttablesubheaderrow}>
								<td className={styles.painttablecell+" "+styles.painttablecelltext} colspan={3} dangerouslySetInnerHTML={{__html:data.system}} />
							</tr>
							:
							<tr className={styles.painttablenotes+" font-size-small font-style-regular"}>
								<td className={styles.painttablecell+" "+styles.painttablecelltext} colspan={3} dangerouslySetInnerHTML={{__html:"**"+data.notes }} />
							</tr>
						)
						:
						<tr className={index===0?styles.painttablefirstrow: styles.painttablerow}>
							<td className={styles.painttablecell+" color-border-primary "+styles.painttablecelltext}>{data.system}</td>
							<td className={styles.painttablecell+" color-border-primary "+styles.painttablecelltext} dangerouslySetInnerHTML={{__html:data.name+(data.notes === ""?"":"**") }} />
							<td className={styles.painttablecell+" color-border-primary "+styles.painttablecellnumber}>{Math.ceil((data.factor * areasqm) - 0.01 )}</td>
						</tr>
				)
			})}
		</tbody>
	</table>
  )
}


PaintTable.propTypes = {
  surface: PropTypes.string,
  areasqm: PropTypes.number
};

PaintTable.defaultProps = {
  surface: `CEPN`,
  areasqm: 30
};

export default PaintTable;

