// extracted by mini-css-extract-plugin
export var container = "style-module--container--81ef6";
export var icon = "style-module--icon--c7cd9";
export var icondescription = "style-module--icondescription--d1ce3";
export var iconsquare = "style-module--iconsquare--3b612";
export var iconwide = "style-module--iconwide--0013f";
export var info = "style-module--info--811d6";
export var infosquare = "style-module--infosquare--5e17e";
export var infowide = "style-module--infowide--b1a85";
export var titlewide = "style-module--titlewide--7f847";
export var uppertitlesquare = "style-module--uppertitlesquare--9e549";
export var uppertitlewide = "style-module--uppertitlewide--35aea";