import React, { Component } from 'react';
import * as styles from "./select.module.css"

import selectarrow from '../../images/select-arrow.png'

class ControlSelect extends Component {
	onValueChange(event) {
		//console.log(event.target.value);
		this.props.handleChange(event.target.value)
	}

	render() {
		return(
			<div className={"selectbox "+styles.selectbox}>
				<select className={styles.selectboxinput} onBlur={this.onValueChange.bind(this)} onChange={this.onValueChange.bind(this)}>
					{this.props.list.map((data, index) => {
						return <option key={data.code} value={data.code}>{data.name}&nbsp;&nbsp;&nbsp;&nbsp;</option>
					})}
				</select>
				<img alt='' src={selectarrow} className={styles.selectboxicon}/>

			</div>
		);
	}
}


export default ControlSelect;

