import React, { Component } from "react"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import * as styles from '../styles/pages/about.module.css'

import HeaderOffset from "../components/header/headeroffset"
import ColumnContent from "../components/columncontent"

import PaintCalculator from "../components/paintcalculator"
import IconDescription from '../components/icondescription'



import image_iconapplication from '../images/about/icon-application.png'
import image_iconcoverage from '../images/about/icon-coverage.png'
import image_icondrytime from '../images/about/icon-drytime.png'
import image_iconefficacy from '../images/about/icon-efficacy.png'
import image_iconfinish from '../images/about/icon-finish.png'
import image_iconpackaging from '../images/about/icon-packaging.png'
import image_iconsolvent from '../images/about/icon-solvent.png'
import image_icontinting from '../images/about/icon-tinting.png'

import image_icondrywall from '../images/about/icon-drywall.png'
import image_iconwooden from '../images/about/icon-wooden.png'


const image_description = '../images/about/description.jpg'
const image_description_mobile = '../images/about/description-mobile.jpg'

const image_technical = '../images/about/technicaldata.jpg'
const image_technical_mobile = '../images/about/technicaldata-mobile.jpg'

const image_howtouse = '../images/about/howtouse.jpg'


export default class AboutPage extends Component {
	render() {
		return (
			<Layout activePath='about' pageTitle="About" pageDescription="A water-based, low-VOC, anti-insect paint that is scientifically proven to kill disease-causing insects like mosquitoes for at least 2 years after application.">
				<div className={styles.container}>
					<StaticImage layout="fullWidth" alt='' src={image_description} className={`fullblock-desktop-only`}/>
					<StaticImage layout="fullWidth" alt='' src={image_description_mobile} className={`fullblock-mobile-only`}/>
					<div className={styles.content+" "+styles.contentdescription}>
						<HeaderOffset/>
						<h2 className={"font-size-large color-text-primary margin-title "+styles.maintitle}>
							<span className="titleboysen">BOYSEN<span className='registeredicon'>&reg;</span> BUG OFF&trade;</span> <span className={`${styles.contentdescriptionsubtitle}`}> ANTI-INSECT PAINT</span>
						</h2>
						<p className="font-size-small">
							A water-based, low-VOC, anti-insect paint that is scientifically proven to kill disease-causing insects like mosquitoes for at least 2 years after application.
						</p>
						<p className="font-size-small">
							It is recommended for walls and ceilings inside the house where it can quietly kill insects that use these surfaces for resting. It can also be used on exterior surfaces where harmful insects may gather.
						</p>
					</div>
				</div>
				<div className={styles.container}>
					<StaticImage layout="fullWidth" alt='' src={image_technical} className={`fullblock-desktop-only`}/>
					<StaticImage layout="fullWidth" alt='' src={image_technical_mobile} className={`fullblock-mobile-only`}/>
					<div className={styles.content}>
						<h2 className={"font-size-large margin-title "+styles.sectiontitle}>
							TECHNICAL DATA
						</h2>
						<ColumnContent>
							<IconDescription iconurl={image_iconfinish} icontitle='Finish:'>Available in Flat</IconDescription>
							<IconDescription iconurl={image_icondrytime} icontitle='Dry Time:'>Touch: 30 minutes<br/>Recoat: 1 hour</IconDescription>
							<IconDescription iconurl={image_iconcoverage} icontitle='Practical Coverage per 4-Liters:'>30 to 35 square meters</IconDescription>
							<IconDescription iconurl={image_icontinting} icontitle='Tinting:'>
								Accepts BOYSEN<span className="registeredicon">&reg;</span> Latex Colors at a maximum level of 1/4 Liter per 4-Liter can. Take note that B-1466 Hansa Yellow and B-1407 Toluidine Red are for interior painting only.
							</IconDescription>
						</ColumnContent>
						<ColumnContent>
							<IconDescription iconurl={image_iconsolvent} icontitle='Clean-up Solvent:'>Water</IconDescription>
							<IconDescription iconurl={image_iconpackaging} icontitle='Packaging:'>Available in 4-Liter variant</IconDescription>
							<IconDescription iconurl={image_iconapplication} icontitle='Application:'>
								By brush or roller.<br/>Do not apply product by spray.
							</IconDescription>
							<IconDescription iconurl={image_iconefficacy} icontitle='Efficacy:'>At least 2 years from the date<br/>of application.</IconDescription>
						</ColumnContent>
					</div>
				</div>
				<div className={styles.container}>
					<div className={`margin-content ${styles.contenttext}`}>
						<h2 className={"font-size-large margin-title text-centered "+styles.sectiontitle}>
							SURFACE PREPARATION
						</h2>
						<div className="font-style-bold">
							New Concrete
						</div>
						<p>
						Allow new concrete to dry for 14-28 days under normal weather conditions before painting. Surface to be painted should be clean and dry, free from oil, grease, dust, dirt, contaminants, and all loose grit and mortar. If neutralizing, treat surface with a solution of 1 part BOYSEN<span className="registeredicon">&reg;</span> Masonry Neutralizer B-44 diluted in 16 parts water, applying liberally by brush or roller. Let dry overnight before rinsing with water to remove white crystals that form on the surface. Let dry before painting. However, if the concrete has been cured for more than 28 days, there is no need for neutralizer.
						</p>
						<div className="font-style-bold">
							Wooden Surfaces
						</div>
						<p>
						Surface to be painted should be clean and dry, free from dust, dirt, and other foreign matter. Hammer all nails deep enough to apply putty. Sand rough wood until smooth and then dust off before painting. Avoid using product on high friction areas.
						</p>

						<div className="font-style-bold">
							Repainting
						</div>
						<p>
						Scrape off all loose, scaling, flaking, and peeling paint manually or by using a paint and varnish remover, as necessary. Wash surfaces clean and completely remove chemical residue. Let it dry.
						</p>
						<p>
						For repainting concrete with chalky old paint, use BOYSEN<span className="registeredicon">&reg;</span> Chalk Blocker&trade; B-7304 as a surface conditioner. In case of mildew infestation, treat with a bleach solution (1:3, laundry bleach to water, respectively) by swabbing or brushing. To ensure proper treatment, let stand for 24 hours before cleaning off residue. Let dry.
						</p>
						<p>
						For repainting other surfaces such as wood, follow the first step then apply the appropriate primer before recoating.
						</p>
					</div>
				</div>
				<hr className="fullblock-mobile-only"/>
				<div id="howtouse" className={styles.container}>
					<StaticImage layout="fullWidth" alt='' src={image_howtouse} className={`fullblock-desktop-only`}/>
					<div className={styles.content+" "+styles.contenthowtouse}>
						<h2 className={"font-size-large margin-title "+styles.sectiontitle+" "+styles.sectiontitlehowtouse}>
							HOW TO USE
						</h2>
						<div className={`${styles.contentinfohowtouse} text-centered`}>
							BOYSEN<span className="registeredicon">&reg;</span> BUG OFF&trade; is recommended for interior and exterior concrete, drywall, and properly primed wooden surfaces. It is applied like normal paint.
						</div>
						<ColumnContent margintype="marginleft">
							<IconDescription iconurl={image_icondrywall} icontitle='For Concrete and Drywall Surfaces' icontype='wide'>
								<div className={styles.contentsurfacetitle+ " font-size-small"}>
									Primer:
								</div>
								<div className={styles.contentsurfacedetail+ " font-size-small"}>
									BOYSEN<span className="registeredicon">&reg;</span> Permacoat&trade; B-701 or EcoPrimer&trade; B-105
								</div>
								<br/>
								<div className={styles.contentsurfacetitle+ " font-size-small"}>
									Putty:
								</div>
								<div className={styles.contentsurfacedetail+ " font-size-small"}>
									BOYSEN<span className="registeredicon">&reg;</span> Masonry Putty B-7311 (Interior)<br/>
									BOYSEN<span className="registeredicon">&reg;</span> Acrytex&trade; Cast B-1711 (Exterior)
								</div>
								<br/>
								<div className={styles.contentsurfacetitle+ " font-size-small"}>
									2nd & 3rd coat:
								</div>
								<div className={styles.contentsurfacedetail+ " font-size-small"}>
									BOYSEN<span className="registeredicon">&reg;</span> BUG OFF&trade;
								</div>
							</IconDescription>
						</ColumnContent>
						<hr className="fullblock-mobile-only"/>
						<ColumnContent margintype="marginleft">
							<IconDescription iconurl={image_iconwooden} icontitle='For Wooden Surfaces' icontype='wide'>
								<div className={styles.contentsurfacetitle+ " font-size-small"}>
									Primer:
								</div>
								<div className={styles.contentsurfacedetail+ " font-size-small"}>
									BOYSEN<span className="registeredicon">&reg;</span> Flatwall Enamel B-800 (Exterior)<br/>
									BOYSEN<span className="registeredicon">&reg;</span> WoodPrime&trade; B-180 (Interior)
								</div>
								<br/>
								<div className={styles.contentsurfacetitle+ " font-size-small"}>
									Putty:
								</div>
								<div className={styles.contentsurfacedetail+ " font-size-small"}>
									BOYSEN<span className="registeredicon">&reg;</span> Plasolux Glazing Putty B-311 (Exterior)<br/>
									BOYSEN<span className="registeredicon">&reg;</span> Masonry Putty B-7311 (Interior)
								</div>
								<br/>
								<div className={styles.contentsurfacetitle+ " font-size-small"}>
									2nd & 3rd coat:
								</div>
								<div className={styles.contentsurfacedetail+ " font-size-small"}>
									BOYSEN<span className="registeredicon">&reg;</span> BUG OFF&trade;
								</div>
							</IconDescription>
						</ColumnContent>
					</div>
				</div>
				<hr className="fullblock-mobile-only"/>
				<div className={styles.container+" margin-content"}>
					<h2 className={"font-size-large text-centered margin-title "+styles.sectiontitle}>
						PAINT CALCULATOR
					</h2>
					<PaintCalculator />
					<div className="font-size-small text-centered">
						* Quantity shown will only be an estimate | 1 gallon = 4 Liters
					</div>
					<br/>
				</div>
			</Layout>
		)
  	}
}

