// extracted by mini-css-extract-plugin
export var container = "about-module--container--a289c";
export var content = "about-module--content--c6c8f";
export var contentdescription = "about-module--contentdescription--a764d";
export var contentdescriptionsubtitle = "about-module--contentdescriptionsubtitle--34961";
export var contenthowtouse = "about-module--contenthowtouse--0c06b";
export var contentinfohowtouse = "about-module--contentinfohowtouse--35051";
export var contentsurfacedetail = "about-module--contentsurfacedetail--f3b55";
export var contentsurfacetitle = "about-module--contentsurfacetitle--dcbaa";
export var contenttext = "about-module--contenttext--811e9";
export var maintitle = "about-module--maintitle--880b3";
export var sectiontitle = "about-module--sectiontitle--dc67b";
export var sectiontitlehowtouse = "about-module--sectiontitlehowtouse--6203f";