import React from "react"
import PropTypes from "prop-types"
import * as styles from "./style.module.css"


const IconDescription = ({ children, icontype, iconurl, icontitle }) => {
  return (
  	<div class={styles.container}>
      <div class={ 'font-style-bold '+ styles['uppertitle'+icontype] }>{icontitle}</div>
      <div class={styles.icon+' '+ styles['icon'+icontype]}>
    		<img class="fullblock" src={iconurl} alt={icontitle}/>
      </div>
  		<div class={ styles.info+' '+styles['info'+icontype]}>
  			<div class={styles["title"+icontype]+" font-style-bold"}>{icontitle}</div>
  			{children}
  		</div>
  	</div>
  )

}
IconDescription.propTypes = {
  icontype: PropTypes.string,
  icontitle: PropTypes.string,
  iconurl: PropTypes.string
}

IconDescription.defaultProps = {
  icontype: `square`,
  icontitle: ``,
  iconurl:``
}

export default IconDescription
