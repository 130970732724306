import React, { Component } from 'react';
import PropTypes from "prop-types"
import * as styles from "./radio.module.css"

class ControlRadio extends Component {
	state = {
		radiovalue: this.props.default
	}

	onValueChange(event) {
		//console.log(event.target.value);
		this.setState({radiovalue: event.target.value})
		this.props.handleChange(event.target.value)
	}

	render() {
		return(
			<>
				{this.props.list.map((data, index) => {
					return (
						<label className={"radiooption "+styles.radiooption}>
							{data.name}
							{ this.state.radiovalue===data.code?
								<input checked="checked" name="radioname" type="radio" value={data.code} onChange={this.onValueChange.bind(this)} />
								:
								<input name="radioname" type="radio" value={data.code} onChange={this.onValueChange.bind(this)} />
							}
							<span class={"radiooptionbox "+styles.radiooptionbox}></span>
						</label>

					)
				})}
			</>
		);
	}
}

ControlRadio.propTypes = {
  default: PropTypes.string
};

ControlRadio.defaultProps = {
  default: ``
};



export default ControlRadio;

